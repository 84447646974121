import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
`;

const Bathroom = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 54 51"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    xmlnsSerif="http://www.serif.com/"
  >
    <g transform="matrix(1,0,0,1,-5,-6.085)">
      <path
        d="M58,37L56,37L13,37L13,12C13,11.199 13.312,10.445 13.878,9.879C14.853,8.904 16.332,8.754 17.476,9.403C15.292,12.068 14.927,15.327 16.736,17.136C16.931,17.331 17.187,17.429 17.443,17.429C17.699,17.429 17.955,17.331 18.15,17.136L26.635,8.651C26.83,8.456 26.928,8.2 26.928,7.944C26.928,7.688 26.83,7.432 26.635,7.237C25.635,6.237 24.164,5.871 22.489,6.206C21.26,6.452 20.028,7.077 18.928,7.969C16.974,6.549 14.224,6.703 12.463,8.465C11.52,9.409 11,10.664 11,12L11,37L8,37L6,37C5.448,37 5,37.447 5,38C5,38.553 5.448,39 6,39L7.307,39L13.064,54.352C13.21,54.741 13.583,55 14,55L15,55L15,56C15,56.553 15.448,57 16,57C16.552,57 17,56.553 17,56L17,55L47,55L47,56C47,56.553 47.447,57 48,57C48.553,57 49,56.553 49,56L49,55L50,55C50.417,55 50.79,54.741 50.937,54.352L56.693,39L58,39C58.553,39 59,38.553 59,38C59,37.447 58.553,37 58,37Z"
        style={{fillRule:'nonzero'}}
      />
    </g>
  </Svg>
);

export default Bathroom;