import styled from 'styled-components';
import React from 'react';

export const ButtonStyle = styled.a`
  display: block;
  padding: 12px 24px !important;
  border-radius: 36px;
  color: #fff !important;
  color: ${props => (props.purple ? '#642C8F' : '#000')};
  border: 2px solid
    ${props => (props.purple ? '#642C8F' : props.theme.primaryColor)};
  font-weight: bold;
  text-decoration: none;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease;
  width: fit-content;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  &:before {
    background-color: ${props =>
      props.purple ? '#fff' : props.theme.primaryColor} !important;;
    width: 100% !important;
    position: absolute;
    right: 0;
    top: 0;
    content: '';
    height: 100% !important;
    z-index: -1;
    transition: all 0.5s ease;
  }
  &:hover {
    border: 2px solid
      ${props => (props.purple ? '#642C8F' : props.theme.primaryColor)};
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.25);
    color: ${props => (props.theme.primaryColor)} !important;
    &:before {
      width: 1px !important;
      right: -1px;
    }
  }
`;

export const Button = ({ to, children, className, title }) => {
  return (
    <ButtonStyle href={to} title={title} className={className}>
      <>{children}</>
    </ButtonStyle>
  );
};
