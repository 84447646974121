import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
`;

const ArrowRight = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 769 899"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    xmlnsSerif="http://www.serif.com/"
  >
    <g transform="matrix(1,0,0,1,711.073,-466.165)">
      <path
        d="M-433.572,1323.04L-475.439,1281.15L-322.945,1128.66L-170.452,976.159L-440.762,976.159L-711.073,976.159L-711.073,916.159L-711.073,856.159L-170.452,856.159L-323.265,703.342L-476.078,550.525L-433.884,508.345L-391.69,466.165L-167.006,690.851L57.677,915.537L-167.014,1140.23L-391.706,1364.91L-433.572,1323.04Z"
        style={{ fillRule: 'nonzero' }}
      />
    </g>
  </Svg>
);

export default ArrowRight;
