import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  fill: ${props => (props.fill ? props.fill : props.theme.primaryColor)};
`;

const Icon = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <rect width="512" height="512" fill="#3b5998" rx="15%"/><path fill="#fff" d="M287 456V157c0-21 6-35 35-35h38V59c-7-1-29-3-55-3-54 0-91 33-91 94v306m143-254H152v72h196"/>
  </Svg>
);
export default Icon;


