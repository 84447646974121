import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Result = styled.article`
  box-sizing: border-box;
  padding: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 300px;

  flex: 0 1;
  p {
    padding: 0;
  }
  ${MEDIA.PHONE`
    min-width: auto;
    width: 100%;
    flex: 0;
    `} .inner {
      background: #fff;
    height: 100%;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    &:hover {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    }
    color: rgba(26, 25, 25, 1);
    transition: all 0.5s ease;
    border: 1px solid ${props => props.theme.primaryColor};
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    ${MEDIA.PHONE`
      flex-direction: column;
      width: 100%;
      overflow: hidden;
    `} &-image {
      position: relative;
      height: 200px;
      width: 300px;
      overflow: hidden;
      box-shadow: 1px 0 4px rgba(0, 0, 0, 0.25);
      img {
        position: absolute;
        top: 50%;
        width: 100%;
        min-height: 100%;
        transform: translate(0, -50%);
      }
    }
    &-content {
      padding: 16px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      max-width: 75ch;
      h4 {
        font-weight: bold;
        padding: 4px 0 8px;
        line-height: 2.2rem;
        min-height: 4.2rem;
        span {
          color: rgba(0, 0, 0, 0.5);
          font-size: 90%;
        }
      }
      &--bottom {
        display: flex;
        padding: 8px 0;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`;
