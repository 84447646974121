import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

const SkiArea = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    enable-background="new 0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <path d="M195.6,252.4c33.4-9.6,45.6-28.2,50-42.1c3.4-10.7,3.1-21.6,1-31.7c14.8,8.1,28.3,18.3,34.3,29.8   c-22.9,12.4-63.8,40-65.9,78.7c-1.1,20.7,8.7,40,29.3,57.9c-4.1,4.7-5.7,11.3-3.8,17.6c2.4,8.1,9.9,13.4,18,13.4   c1.8,0,3.6-0.2,5.3-0.8l25.6-7.6c7.2-2.1,12.5-8.4,13.3-15.9c0.9-7.5-2.8-14.7-9.4-18.4c-27-15.3-41.5-31-40.8-44.3   c1.1-21.8,39.4-45.4,57.8-53.4c7.2-3.1,11.7-10.4,11.3-18.3c-0.7-12.1-4.7-22.9-10.8-32.3l115.5-82.6c8.4-6,10.4-17.7,4.3-26.1   c-6-8.4-17.7-10.4-26.2-4.4L284.2,158c-28.6-21-64.1-30.9-70.4-32.6c-7.9-2.1-16.1,1.2-20.5,8c-4.4,6.8-3.9,15.7,1.2,22   c6.8,8.4,19.5,30.1,15.2,43.8c-2.3,7.4-10.6,13.2-24.6,17.3c-10,2.9-15.7,13.3-12.8,23.2C175.2,249.5,185.6,255.2,195.6,252.4z" />
      <path d="M171.6,115.4c0-25.9-21-46.9-46.9-46.9c-25.9,0-46.9,21-46.9,46.9c0,25.9,21,46.9,46.9,46.9   C150.6,162.3,171.6,141.3,171.6,115.4z" />
      <path d="M433.3,344.2c-3-9.9-13.6-15.5-23.4-12.4l-233,71.7L147.4,374c-7.3-7.3-19.2-7.3-26.5,0c-7.3,7.3-7.3,19.2,0,26.5   l37.5,37.5c3.6,3.6,8.4,5.5,13.3,5.5c1.8,0,3.7-0.3,5.5-0.8l243.7-75C430.8,364.6,436.3,354.1,433.3,344.2z" />
    </g>
  </Svg>
);

export default SkiArea;
