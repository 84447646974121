import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  fill: ${props => (props.fill ? props.fill : props.theme.primaryColor)};
`;

const Icon = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 27 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.84615 0C1.48818 0 1.15737 0.103871 0.875 0.278846L12.3077 9.48077C12.9045 9.96114 13.5369 9.96105 14.1346 9.48077L25.5865 0.278846C25.3042 0.103871 24.9734 0 24.6154 0H1.84615ZM0.105769 1.23077C0.0380339 1.42331 0 1.62988 0 1.84615V14.1538C0 15.1766 0.823382 16 1.84615 16H24.6154C25.6382 16 26.4615 15.1766 26.4615 14.1538V1.84615C26.4615 1.62988 26.4235 1.42331 26.3558 1.23077L14.9038 10.4423C13.9117 11.2395 12.5305 11.2407 11.5385 10.4423L0.105769 1.23077Z"
    ></path>
  </Svg>
);
export default Icon;
