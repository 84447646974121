import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  fill: ${props => props.theme.secondaryColor};
`;

const ArrowRight = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    xmlnsDc="http://purl.org/dc/elements/1.1/"
    xmlnsCc="http://creativecommons.org/ns#"
    xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlnsSvg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlSpace="preserve"
    version="1.1"
    viewBox="0 0 64 80"
    x="0px"
    y="0px"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="M 33.793,5.7886 C 33.457,5.1056 32.761,4.6736 32,4.6736 c -0.761,0 -1.457,0.432 -1.793,1.115 0,0 -5.145,10.424 -7.299,14.788 -0.291,0.59 -0.854,0.999 -1.506,1.094 -4.816,0.7 -16.319,2.371 -16.319,2.371 -0.754,0.11 -1.379,0.637 -1.615,1.361 -0.235,0.725 -0.039,1.519 0.506,2.051 0,0 8.325,8.114 11.809,11.511 0.472,0.459 0.687,1.121 0.576,1.77 -0.823,4.796 -2.788,16.253 -2.788,16.253 -0.129,0.751 0.18,1.509 0.795,1.957 0.616,0.447 1.433,0.506 2.107,0.152 0,0 10.289,-5.41 14.596,-7.674 0.583,-0.306 1.279,-0.306 1.862,0 4.307,2.264 14.596,7.674 14.596,7.674 0.674,0.354 1.491,0.295 2.107,-0.152 0.615,-0.448 0.924,-1.206 0.795,-1.957 0,0 -1.965,-11.457 -2.788,-16.253 -0.111,-0.649 0.104,-1.311 0.576,-1.77 3.484,-3.397 11.809,-11.511 11.809,-11.511 0.545,-0.532 0.741,-1.326 0.506,-2.051 -0.236,-0.724 -0.861,-1.251 -1.615,-1.361 0,0 -11.503,-1.671 -16.319,-2.371 -0.652,-0.095 -1.215,-0.504 -1.506,-1.094 -2.154,-4.364 -7.299,-14.788 -7.299,-14.788 z"
      // fill="#000000"
    />
  </Svg>
);

export default ArrowRight;
