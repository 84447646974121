import styled from "styled-components";
import MEDIA from "../../helpers/mediaTemplates";

export const Container = styled.div`
  //

  /* padding: 32px 0; */
  h1,
  h2,
  p {
    padding: 8px 16px;
    box-sizing: border-box;
    line-height: 1.4;
  }
  p {
    max-width: 75ch;
    font-size: 1.6rem;
    text-align: left;
  }
  ul {
    list-style: initial;
    list-style-position: inside;
    li {
      font-size: 1.6rem;
      line-height: 1.4;
      font-family: "Montserrat";
    }
  }
`;

export const RegionHero = styled.section`
  height: ${(props) => (props.image ? "500px" : "auto")};
  overflow: hidden;
  position: relative;
  padding: 32px 0;
  .hero {
    &-image {
      img {
        position: absolute;
        // height: 100%;
        // max-width: 100%;
        top: 50%;
        width: 100%;
        min-height: 100%;
        transform: translate(0, -50%);
        z-index: -1;
      }
    }
  }
  .hero-inner {
    max-width: 1440px;
    margin: 0 auto;
    padding: 32px 8px;
    height: 100%;
    display: flex;
    align-items: center;
    h1 {
      color: ${(props) => (props.image ? "#fff" : "inherit")};
      font-size: 64px !important;
      text-shadow: ${(props) => (props.image ? "1px 1px #000" : "inherit")};
    }
  }
`;

export const HousesContainer = styled.div`
  h2 {
    width: 100%;
    box-sizing: border-box;
  }
  background: #f6f6f6;
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
  padding: 16px 10%;
  ${MEDIA.PHONE`
    display: block;
    `};
`;

export const TitleImage = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  width: 100%;
  h1 {
    padding: 0 64px;
  }

  .image-next {
    /* flex: 1 1 auto; */
    height: 400px;
    width: 100%;
    ${MEDIA.PHONE`
    display: none;
    `};
  }
  .image-next-title {
    height: 400px;
    margin-right: -50%;
    overflow: hidden;
    position: relative;
    transform: skew(-40deg);
    .image-holder {
      overflow: hidden;
      width: 100%;

      img {
        transform: skew(40deg);
        position: absolute;
        max-width: 100%;
        /* max-height: 200%; */
        /* top: -50%; */
        left: -20%;
      }
    }
  }
`;

export const Row = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  padding: 32px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  &.text-area {
    h4 {
      font-weight: bold;
    }
    justify-content: flex-start;
    p {
      max-width: 90ch;
      padding: 16px 0;
    }
  }

  .ski-area {
    max-width: 250px;
    text-align: center;
    h3 {
      padding: 0 0 16px;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 16px;
      &:before {
        position: absolute;
        content: "";
        top: 22px;
        width: 100%;
        left: 0;
        height: 1px;
        border-top: 1px dotted ${(props) => props.theme.primaryColor};
      }
      &:after {
        position: absolute;
        content: "";
        bottom: 22px;
        width: 100%;
        left: 0;
        height: 1px;
        border-top: 1px dotted ${(props) => props.theme.primaryColor};
      }
      span.top {
        position: absolute;
        left: 16px;
        top: 16px;
        font-weight: bold;
        background: #fff;
        padding: 0 4px;
      }
      span.bottom {
        position: absolute;
        right: 16px;
        bottom: 16px;
        font-weight: bold;
        background: #fff;
        padding: 0 4px;
        z-index: 1;
      }
    }
  }
`;
