import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
  fill: ${props => (props.fill ? props.fill : props.theme.primaryColor)};
`;

const ChevronRight = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 604 943"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    xmlnsSerif="http://www.serif.com/"
  >
    <g transform="matrix(1,0,0,1,122.029,-2002.44)">
      <path
        d="M-57.037,2879.94L-122.029,2814.94L48.905,2644L219.84,2473.06L49.84,2303.07C-43.66,2209.57 -120.16,2132.5 -120.16,2131.81C-120.16,2130.18 7.584,2002.44 9.221,2002.44C9.906,2002.44 116.497,2108.47 246.09,2238.07L481.712,2473.69L246.09,2709.32C116.497,2838.91 9.902,2944.94 9.212,2944.94C8.521,2944.94 -21.291,2915.69 -57.037,2879.94Z"
        style={{ fillRule: 'nonzero' }}
      />
    </g>
  </Svg>
);
export default ChevronRight;
