import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
`;

const Bedroom = ({ width, height, fill }) => (
  <Svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 84 60"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    xmlnsSerif="http://www.serif.com/"
  >
    <g transform="matrix(1,0,0,1,-8,-20)">
      <path
        d="M92,56L92,77C92,78.657 90.657,80 89,80C87.343,80 86,78.657 86,77L86,74L14,74L14,77C14,78.657 12.657,80 11,80C9.343,80 8,78.657 8,77L8,56C8,51.037 12.037,47 17,47L83,47C87.963,47 92,51.037 92,56Z"
        style={{fillRule:'nonzero'}}
      />
      <path
        d="M17,41L17,23C17,21.343 18.343,20 20,20L80,20C81.657,20 83,21.343 83,23L83,41L74,41L74,38C74,34.691 71.309,32 68,32L59,32C55.691,32 53,34.691 53,38L53,41L47,41L47,38C47,34.691 44.309,32 41,32L32,32C28.691,32 26,34.691 26,38L26,41L17,41Z"
        style={{fillRule:'nonzero'}}
      />
    </g>
  </Svg>
);

export default Bedroom;
