import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../Button';
import { Result } from './HousePreview.css';
import Bedroom from '../../icons/bedroom.svg';
import Bathroom from '../../icons/bathroom.svg';
import {Persons} from '../../icons';

const HousePreview = ({ house, locale }) => {

  const data = JSON.parse(house.field);

  let img;
    if (data.images[0]) {
      if (data.images[0].reference) {
        if (data.brand === 'BUKAZU') {
          img = data.images[0].reference;
        } else {
          img =
            'https://cms.burobork.nl/images/' + data.images[0].reference;
        }
      } else {
        if (data.brand === 'LEISURE') {
          img = 'https://' + data.images[0].preview;
        } else {
          img = data.images[0].preview;
        }
      }
    }

  // const
  let house_url;
  if (locale === 'nl') {
    house_url = '/' + data.slug + '-' + data.house_code;
  } else {
    house_url = '/' + locale + '/' + data.slug + '-' + data.house_code;
  }
  return <Result>
      <div className="inner">
        <div className="inner-image">
          <img src={img} alt={house.name} />
        </div>
        <div className="inner-content">
          <h4>
            {house.name}, {data.city}
          </h4>
          <div style={{ padding: '8px 0' }} dangerouslySetInnerHTML={{ __html: data[`short_description_${locale}`] ? data[`short_description_${locale}`] : '' }} />
          <div className="inner-content--bottom">
            <div>
              <Persons width="32px" height="16px" />
              {data.persons}
            </div>
            <div>
              <Bedroom width="32px" height="16px" />
              {data.bedrooms}
            </div>
            <div>
              <Bathroom width="32px" height="16px" />
              {data.bathrooms}
            </div>
          </div>
          <Button to={house_url} title={house.name}>
            <FormattedMessage id="show_house" />
          </Button>
        </div>
      </div>
    </Result>;
};

export default HousePreview;
