import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import Layout from "../../components/Layout";
import Head from "../../components/Layout/Head";
import { Container, HousesContainer, RegionHero } from "./region.css";
import HousePreview from "../../components/HousePreview";
// import { Button } from 'components/button';
// import HousePreview from 'components/housePreview';

const RegionTemplate = ({ data, pageContext }) => {
  const { locale } = pageContext;

  let houses = [];
  if (data.allBuroBorkHouse) {
    houses = data.allBuroBorkHouse.edges;
  }
  let regio = data.regio;
  let regio_data = {};
  if (regio) {
    regio_data = JSON.parse(regio.field);
  }
  if (!regio) {
    regio = {
      title: "",
    };
  }
  const { belvillaRegion } = data;
  const title = belvillaRegion.titles[locale];

  let img = false;
  if (regio_data.fields && regio_data.fields?.Hoofdafbeelding) {
    img = regio_data?.fields["Hoofdafbeelding"][0].reference;
  }

  return (
    <Layout locale={locale}>
      <Head pageTitle={title ? title : ""} locale={locale} />
      <RegionHero image={img ? true : false}>
        <div className="hero-image">
          {img && <img src={"https://cdn.burobork.nl/" + img}></img>}
        </div>
        <div className="hero-inner">
          <div className="hero-inner-text">
            <h1>{title ? title : ""}</h1>
          </div>
        </div>
      </RegionHero>
      <Container>
        <HousesContainer>
          <h2>
            <FormattedMessage id="holidayvillas" />
          </h2>
          {houses.map((house) => {
            return (
              <HousePreview
                house={house.node}
                locale={locale}
                key={house.node.id}
              />
            );
          })}
        </HousesContainer>
      </Container>
    </Layout>
  );
};

export const regionQuery = graphql`
  query BuroBorkRegion($name: String!, $cmsId: String) {
    allBuroBorkHouse(filter: { region_name: { eq: $name } }) {
      edges {
        node {
          id
          name
          field
        }
      }
    }
    regio(import_id: { eq: $cmsId }) {
      id
      field
    }
    belvillaRegion(name: { eq: $name }) {
      id
      titles {
        nl
        de
        en
      }
      cms_id
      name
    }
  }
`;

export default RegionTemplate;
